
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { formatDate } from '@/util/global';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'

// api
import { detailPurchase, queryFactory,pickPurchase } from '@/api/factory/goodRejected';

// 组件
import CustomModal from '@/component/custom-modal.vue';
@Component({
    components: {
        CustomModal,
    },
})
export default class GoodsRejectedDetail extends Mixins (Validate) {
    is_loading: boolean = false;
    purchase_id: any = '';
    receivingList: any = [];
    next_page: number = 1;
    factoryList: any = [];
    // 表单布局
    form_item_layout: any = {
        labelCol: { span: 2 },
        wrapperCol: { span: 22 },
    };
    model_layout: any = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };

    detailFrom: any = {}
    // 表单校验和参数
    validator_list: any[] = [
      {
        field: 'factory_id',
        message: '请选择发货工厂',
      },
    ];
    form: any = {
      factory_id: '',
      factory_address: '',
    }
    validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

    // 确认修改状态弹窗
    add_modal: any = {
        title: '',
        visible: false,
        is_loading: false,
    }
    // 失败弹窗
    fail_modal: any = {
        title: '抱歉，因以下原因接单失败',
        visible: false,
        content: [],
    };
    get logo(){
      if(this.detailFrom.status == '待接单' ){
        return require('@/asset/img/await-order.png') 
      }else{
        return require('@/asset/img/accept-order.png')
      }
     
    }
    creatQrCode() {
      new QRCode(this.$refs.rejectQrCodeUrl, {
        text: this.detailFrom.code, // 需要转换为二维码的内容
        width: 70,
        height: 70,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
    back() {
        // this.$router.go(-1);
        this.$router.push({
        path: '/factory/goodRejected',
        query: { page: this.$route.query?.page || '1' },
        });
    }
    fromDetail(remote: any = {}) {
        return {
            ...remote,
            expect_arrival_time: formatDate(remote.expect_arrival_time),
            status: remote.status == 1?'待接单':'已接单',
            create_time: formatDate(remote.create_time),
            // order_pd: [{pd_name: 1},{pd_name: 2},{pd_name: 1},{pd_name: 2},{pd_name: 1},{pd_name: 2},{pd_name: 1},{pd_name: 2},]
        };
    }
    factoryData(remote: any = {}) {
      console.log(remote)
      const arr: any = [];
      remote.map((item: any)=>{
        arr.push({
          factory_id: item.id,
          factory_name: item.name,
          factory_address: item.address,
          receiver_name: item.receiver,
          receiver_mobile: item.receiver_mobile
        });
      })
      return arr;
    }
    // 手机号校验
    checkTel(value: any) {
        const isMob = /^((\+?86)|(\(\+86\)))?(13[0123456789][0-9]{8}|15[0123456789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
        if (isMob.test(value)) {
        return true;
        } else {
        return false;
        }
    }

    // 接单
    add() {
      this.add_modal.title = '接单';
      this.add_modal.data = this.detailFrom;
      this.add_modal.visible = true;
    }
    // 提交
    @changeLoading(['is_loading'])
    async submit() {
      // 校验
      if (!this.validateCommit()) {
          return;
      }
      this.add_modal.is_loading = true;
      this.factoryList.map(
        (item: any)=>{
          if (item.factory_id == this.form.factory_id) {
            this.form.factory_name = item.factory_name
          }
        }
      )
      const data = this.form
      console.log(data)
      const res: any = await pickPurchase(this.detailFrom.id, data);
      this.add_modal.is_loading = false;
      this.add_modal.visible = false;
      if (res.status !== 200) {
      this.fail_modal.content = [...(res as any).message.split('\n')];
      this.fail_modal.visible = true;
      return;
      }
      this.$message.success('接单成功');
      this.clearModal();
      this.$router.push({
        path: '/factory/goodRejected',
        query: { page: this.$route.query?.page || '1' },
      });
    }
    // 取消
    cancel() {
        this.clearModal();
    }
    // 清空模态框数据
    clearModal() {
        // 清除校验信息
        this.add_modal = {
            title: '',
            visible: false,
            is_loading: false,
        }
        this.form = {
          factory_id: '',
          factory_address: '',
        }
        this.$nextTick(() => {
            this.resetValidatorStatus();
        });
    }

    // 工厂地址
    onChangeFactory(e: any) {
      this.factoryList.map(
        (item: any)=>{
          if (item.factory_id == e) {
            this.form = {
              ...this.form,
              factory_address: item.factory_address,
              receiver_name: item.receiver_name,
              receiver_mobile: item.receiver_mobile
            }
          }
        }
      )
    } 
    printDisabled: boolean = false

    printBill () {
        this.printDisabled = true  // 点击打印按钮禁止重复点击
        const el: HTMLElement = document.getElementById('openPrint') as HTMLElement
        const iframe: HTMLIFrameElement = document.getElementById('iframe') as HTMLIFrameElement
        const content = iframe.contentWindow 
        setTimeout(() => {      // 按钮显示为禁止了再去执行截图功能
          html2canvas(el, {
            backgroundColor: null,
            scale: 1.3
          }).then((canvas) => {
            const dataURL = canvas.toDataURL('image/png')
            // eslint-disable-next-line no-unexpected-multiline
            content!.document.write('<html><head><style media="print">@page { margin: 0mm 10mm; }body{margin-top: 50px; text-align: center; width:800px}img{width:1000px}</style></head><body><img src=' + dataURL + '></body></html>')
            setTimeout(() => {
              // eslint-disable-next-line no-unexpected-multiline
              content!.print()
              // eslint-disable-next-line no-unexpected-multiline
              content!.document.body.innerHTML = ''  // 清空上一次打印的内容
            }, 0)
            this.printDisabled = false
          })
        }, 100)
      }

    @changeLoading(['is_loading'])
    async created() {
      if (this.$route.query.id) {
        this.purchase_id = this.$route.query.id;
        const res: any = await detailPurchase(this.purchase_id);
        this.detailFrom = this.fromDetail(res.data);
        const res1: any = await queryFactory();
        this.factoryList = this.factoryData(res1);
        this.creatQrCode()
      }
    }
}
