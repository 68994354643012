import { render, staticRenderFns } from "./goodRejectedDetail.vue?vue&type=template&id=309b3a48"
import script from "./goodRejectedDetail.vue?vue&type=script&lang=ts"
export * from "./goodRejectedDetail.vue?vue&type=script&lang=ts"
import style0 from "./goodRejectedDetail.vue?vue&type=style&index=0&id=309b3a48&prod&lang=stylus"
import style1 from "./goodRejectedDetail.vue?vue&type=style&index=1&id=309b3a48&prod&module=true&lang=stylus"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports